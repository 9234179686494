import GetApp from '@material-ui/icons/GetApp';
import { Button } from 'react-admin';
import React from 'react';
import { WithdrawalFundsRequest } from '../types';
import { download } from './download';

interface Props {
  record: WithdrawalFundsRequest;
}

const DOCXActButton = (props: Props) => {
  return (
    <Button label={'Акт в DOCX'} onClick={() => download(props.record, 'act')}>
      <GetApp />
    </Button>
  );
};

export default DOCXActButton;
