import React from 'react';
import { ShowButton, TopToolbar, ListButton } from 'react-admin';
import BulkAcceptButton from '../BulkAcceptButton';
import { WithdrawalFundsRequestsActionsProps } from './types';
import { NEW } from '../constants';
import XLSXReportButton from './XLSXReportButton';
import DOCXActButton from './DOCXActButton';

const WithdrawalFundsRequestsEditActions: React.FC<WithdrawalFundsRequestsActionsProps> = (
  props,
) => (
  <TopToolbar>
    <BulkAcceptButton
      basePath={props.basePath}
      resource={props.resource}
      selectedIds={[props.data.id]}
      isChecked={props.data ? props.data.status !== NEW : false}
    />
    <ListButton label="Список" basePath={props.basePath} />

    <ShowButton
      label="Просмотр"
      record={props.data}
      basePath={props.basePath}
      resource={props.resource}
    />
    <XLSXReportButton record={props.data} />
    <DOCXActButton record={props.data} />
  </TopToolbar>
);

export default WithdrawalFundsRequestsEditActions;
