import GetApp from '@material-ui/icons/GetApp';
import { Button } from 'react-admin';
import React from 'react';
import { WithdrawalFundsRequest } from '../types';
import { download } from './download';

interface Props {
  record: WithdrawalFundsRequest;
}

const XLSXReportButton = (props: Props) => {
  return (
    <Button
      label={'Отчет в XLSX'}
      onClick={() => download(props.record, 'report')}
    >
      <GetApp />
    </Button>
  );
};

export default XLSXReportButton;
