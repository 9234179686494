import React from 'react';
import {
  ArrayField,
  Datagrid,
  FunctionField,
  Show,
  SimpleShowLayout,
  TextField,
} from 'react-admin';
import PriceField from '../fields/PriceField';
import { Typography } from '@material-ui/core';
import DateFieldForTimestamp from '../fields/dateFieldForTimestamp';
import { formatPrice } from '../utils/format';

const FundShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="mail" />
      <TextField source="givenName" />
      <PriceField source="currentBalance" addLabel={true} />
      <TextField source="id" />

      <Typography variant="h5" gutterBottom>
        Платежи
      </Typography>
      <ArrayField source="paymentsHistory" fieldKey="_id" addLabel={false}>
        <Datagrid>
          <DateFieldForTimestamp source="created" label="Создан" />
          <TextField source="type" label="Тип" />
          <TextField source="data.courseName" label="Название курса" />
          <PriceField source="amount" label="Сумма" />
          <TextField source="data.coursePlanId.name" label="Название тарифа" />
          <FunctionField
            label="Стоимость тарифа"
            //@ts-expect-error
            render={({ data: { coursePlanId: coursePlan } }) =>
              coursePlan
                ? coursePlan.type === 'FREE'
                  ? 'бесплатный'
                  : formatPrice(coursePlan.price)
                : ''
            }
          />
        </Datagrid>
      </ArrayField>
    </SimpleShowLayout>
  </Show>
);

export default FundShow;
