import {
  Datagrid,
  DateField,
  DateInput,
  Filter,
  FunctionField,
  List,
  SelectInput,
  TextField,
  TextInput,
} from 'react-admin';
import React from 'react';
import PriceField from '../fields/PriceField';
import { endOfMonth, format, startOfMonth } from 'date-fns';
import { STATUS_OPTIONS, StatusLabels } from './constants';

export const renderOrderType = (record) => {
  if (record.type === 'INITIAL_INVOICE') {
    return 'Покупка подписки';
  } else if (record.type === 'INITIAL_ADDON_INVOICE') {
    return 'Покупка аддона';
  } else {
    return record.type;
  }
};

export const renderOrderStatus = (record) =>
  StatusLabels[record.status] || record.status;

const AddonPurchaseFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Сумма платежа" source="totalAmount" alwaysOn />
    <TextInput label="ИНН организации" source="inn" alwaysOn />
    <TextInput label="Имя организации" source="name" alwaysOn />
    <SelectInput
      alwaysOn
      source="status"
      label="Статус"
      choices={STATUS_OPTIONS}
    />
    <DateInput
      label="Дата выставления счета от"
      source="from"
      defaultValue={format(startOfMonth(new Date()), 'yyyy-MM-dd')}
    />
    <DateInput
      label="Дата выставления счета по"
      source="to"
      defaultValue={format(endOfMonth(new Date()), 'yyyy-MM-dd')}
    />
  </Filter>
);

export const InvoiceList = (props) => {
  return (
    <List
      {...props}
      bulkActionButtons={false}
      filters={<AddonPurchaseFilter />}
    >
      <Datagrid rowClick="show">
        <DateField source="createdAt" label="Дата покупки" />
        <FunctionField label="Способ приобретения" render={renderOrderType} />
        <FunctionField label="Статус" render={renderOrderStatus} />
        <PriceField source="totalAmount" label="Сумма платежа" />
        <TextField source="planName" label="Тариф" />
        <TextField source="addonTitles" label="Аддоны" />
        <TextField source="name" label="Наименование организации" />
        <TextField source="inn" label="ИНН организации" />
      </Datagrid>
    </List>
  );
};
