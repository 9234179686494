import jwt from 'jsonwebtoken';
import { JWT_SECRET } from '../config';

const tokenKey = 'token';
const grantsKey = 'grants';

export const decodeToken = (token: string) => {
  if (!token) return null;

  try {
    return jwt.verify(token, JWT_SECRET as string);
  } catch {
    return null;
  }
};

export const clearAuth = () => {
  localStorage.removeItem(tokenKey);
  localStorage.removeItem(grantsKey);
};

export const storeAuth = (token: string, grants: string) => {
  const decoded = decodeToken(token);

  if (!decoded) {
    clearAuth();
    return;
  }

  localStorage.setItem(tokenKey, token);
  localStorage.setItem(grantsKey, grants);
};

export const getToken = (): string | null => {
  const token = localStorage.getItem(tokenKey) || '';

  if (decodeToken(token)) {
    return token;
  }
  return null;
};

export const getGrants = () => {
  const grants = localStorage.getItem(grantsKey);

  return JSON.parse(grants || '{}');
};
