import { makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import {
  ArrayField,
  BooleanField,
  Datagrid,
  DateField,
  FunctionField,
  Show,
  SimpleShowLayout,
  TextField,
  useRecordContext,
} from 'react-admin';
import ReactJson from 'react-json-view';
import DateFieldForTimestamp from '../fields/dateFieldForTimestamp';
import PriceField from '../fields/PriceField';
import { formatPrice, formatUnix } from '../utils/format';

const renderJson = (record, source) => (
  <ReactJson src={record?.[source]} name={null} displayDataTypes={false} />
);

const useStyles = makeStyles({
  layout: {
    display: 'flex',
    flexWrap: 'wrap',
    '& .ra-field': {
      margin: '0 12px;',
    },
  },
  orders: {
    width: '100%',
    '& .MuiFormControl-root': {
      width: '100%',
    },
  },
  externalPaymentEvents: {
    width: '100%',
    flexGrow: 1,
  },
  addons: {
    width: '100%',
    flexGrow: 1,
  },
});

const SubscriptionView = () => {
  const record = useRecordContext();
  return record?.subscription ? (
    <div style={{ width: 200, margin: '1em' }}>
      <Typography variant="h6">Подписка</Typography>
      <SimpleShowLayout record={record.subscription}>
        <TextField source="planName" label="planName" />
        <TextField source="status" label="status" />
        <FunctionField
          label="nextPaymentAmount"
          source="nextPaymentAmount"
          render={(record, source) => {
            const price = source ? record?.[source] : undefined;
            return formatPrice(price ? price / 100 : 0);
          }}
        />
        <TextField
          source="nextPaymentDayOfMonth"
          label="nextPaymentDayOfMonth"
        />
        <FunctionField
          label="nextPaymentAt"
          source="nextPaymentAt"
          render={(record, source) =>
            source ? formatUnix(record?.[source]) : null
          }
        />
        <TextField source="paymentPeriod" label="paymentPeriod" />
        <BooleanField source="expired" label="expired" />
        <FunctionField
          label="Создан"
          source="created"
          render={(record, source) =>
            source ? formatUnix(record?.[source]) : null
          }
        />
        <FunctionField
          label="Изменён"
          source="modified"
          render={(record, source) =>
            source ? formatUnix(record?.[source]) : null
          }
        />
      </SimpleShowLayout>
    </div>
  ) : null;
};

const ExternalPaymentEventView = ({ record }) => {
  return (
    <SimpleShowLayout record={record}>
      <FunctionField label="data" source="data" render={renderJson} />
      <FunctionField
        label="requestParams"
        source="requestParams"
        render={renderJson}
      />
      <FunctionField label="exception" source="exception" render={renderJson} />
    </SimpleShowLayout>
  );
};

const OrderView = ({ record }) => {
  const styles = useStyles();

  return (
    <SimpleShowLayout
      resource="payment-order"
      record={record}
      className={styles.layout}
    >
      <FunctionField
        source="modified"
        label="Изменён"
        render={(record, source) =>
          source ? formatUnix(record?.[source]) : null
        }
      />
      <TextField source="paymentId" label="paymentId" />
      <TextField source="externalId" label="externalId" />
      <TextField source="nextPaymentDayOfMonth" label="nextPaymentDayOfMonth" />
      <TextField source="paymentPeriod" label="paymentPeriod" />
      <TextField source="extraDays" label="extraDays" />
      <TextField source="promocodeName" label="promocodeName" />
      <ArrayField
        source="externalPaymentEvents"
        fieldKey="_id"
        className={styles.externalPaymentEvents}
      >
        <Datagrid expand={ExternalPaymentEventView}>
          <FunctionField
            label="Создан"
            source="created"
            render={(record, source) =>
              source ? formatUnix(record?.[source]) : null
            }
          />
          <TextField source="type" label="Тип" />
          <BooleanField source="accepted" label="accepted?" />
          <TextField source="rejectionReason" label="rejectionReason" />
        </Datagrid>
      </ArrayField>
      <ArrayField source="addons" fieldKey="_id" className={styles.addons}>
        <Datagrid>
          <TextField source="name" label="Название" />
          <PriceField source="amount" label="Сумма" />
          <PriceField source="actualAmount" label="Фактическая сумма" />
          <DateField source="nextPaymentAt" label="nextPaymentAt" />
          <TextField source="paymentPeriod" label="Периодичность оплаты" />
          <TextField source="status" label="Статус" />
        </Datagrid>
      </ArrayField>
    </SimpleShowLayout>
  );
};

const UserShow = (props) => {
  const styles = useStyles();

  return (
    <Show {...props} aside={<SubscriptionView />}>
      <SimpleShowLayout>
        <TextField source="givenName" label="Имя" />
        <TextField source="mail" label="email" />
        <TextField source="oldEmails" label="Старые email" />
        <ArrayField source="orders" fieldKey="_id" className={styles.orders}>
          <Datagrid expand={OrderView}>
            <DateFieldForTimestamp source="created" label="Создан" />
            <TextField source="planName" label="Тарифный план" />
            <TextField source="type" label="Тип" />
            <TextField source="status" label="Статус" />
            <PriceField source="amount" label="Сумма" />
            <PriceField source="addonsAmount" label="Сумма за аддоны" />
            <DateFieldForTimestamp
              source="nextPaymentAt"
              label="nextPaymentAt"
            />
          </Datagrid>
        </ArrayField>
        <ArrayField
          source="actionLogs"
          fieldKey="_id"
          className={styles.orders}
        >
          <Datagrid>
            <DateFieldForTimestamp source="created" label="Дата создания" />
            <TextField source="subscriptionPlan" label="Тарифный план" />
            <TextField source="actionType" label="Тип" />
          </Datagrid>
        </ArrayField>
      </SimpleShowLayout>
    </Show>
  );
};

export default UserShow;
