import { SelectOption } from '../types';

export const NEW = 'NEW';
export const ACCEPTED = 'ACCEPTED';
export const DECLINED = 'DECLINED';

export const StatusLabels = {
  DECLINED: 'Заявка отклонена',
  NEW: 'Заявка на рассмотрении',
  ACCEPTED: 'Заявка подтверждена',
};

export const STATUS_OPTIONS: SelectOption[] = [
  { id: NEW, name: StatusLabels.NEW },
  { id: ACCEPTED, name: StatusLabels.ACCEPTED },
  { id: DECLINED, name: StatusLabels.DECLINED },
];
