import React from 'react';
import {
  Show,
  TextField,
  FunctionField,
  SimpleShowLayout,
  useShowController,
  Datagrid,
  ArrayField,
} from 'react-admin';
import { Box, Typography } from '@material-ui/core';

import WithdrawalFundsRequestsActions from './actions/withdrawalRequestsActions';
import { StatusLabels } from './constants';
import { WithdrawalFundsRequest } from './types';
import { formatPrice } from '../utils/format';
import DateFieldForTimestamp from '../fields/dateFieldForTimestamp';
import PriceField from '../fields/PriceField';

const WithdrawalFundsRequestShow = (props) => {
  const { record } = useShowController(props);

  return record ? (
    <Show
      actions={
        <WithdrawalFundsRequestsActions
          // @ts-expect-error
          data={record}
          basePath={props.basePath}
          resource={props.resource}
        />
      }
      {...props}
    >
      <SimpleShowLayout>
        <TextField label="Пользователь" source="user" />
        <TextField source="givenName" label="Имя" />
        <TextField source="mail" label="email" />

        <FunctionField
          label="Статус"
          // @ts-expect-error
          render={({ status }: WithdrawalFundsRequest) =>
            `${StatusLabels[status]}`
          }
        />
        <TextField source="id" />

        <FunctionField
          label="Доступно"
          // @ts-expect-error
          render={({ amount }: WithdrawalFundsRequest) =>
            formatPrice(amount / 100)
          }
        />
        <FunctionField
          label="Комиссия"
          // @ts-expect-error
          render={({ commission }: WithdrawalFundsRequest) =>
            formatPrice(commission ? commission / 100 : 0)
          }
        />
        <FunctionField
          label="Удержано"
          // @ts-expect-error
          render={({ withheld }: WithdrawalFundsRequest) =>
            formatPrice(withheld ? withheld / 100 : 0)
          }
        />
        <FunctionField
          label="Итого к выплате"
          // @ts-expect-error
          render={({ received }: WithdrawalFundsRequest) =>
            formatPrice(received ? received / 100 : 0)
          }
        />
        <TextField source="files" label="Платежное поручение" />

        <Box mt="1em" />

        <Typography variant="h5" gutterBottom>
          Банковские данные
        </Typography>
        <TextField source="paymentAccount" label="Расчётный счёт" />
        <TextField source="bank" label="Банк" />
        <TextField source="bik" label="БИК" />
        <TextField source="corrAccount" label="Корреспондентский счёт" />

        <Box mt="1em" />

        <Typography variant="h5" gutterBottom>
          Платежи
        </Typography>
        <ArrayField source="paymentsHistory" fieldKey="_id" addLabel={false}>
          <Datagrid>
            <DateFieldForTimestamp source="created" label="Создан" />
            <TextField source="type" label="Тип" />
            <TextField source="data.courseName" label="Название курса" />
            <PriceField source="amount" label="Сумма" />
            <TextField
              source="data.coursePlanId.name"
              label="Название тарифа"
            />
            <FunctionField
              label="Стоимость тарифа"
              //@ts-expect-error
              render={({ data: { coursePlanId: coursePlan } }) =>
                coursePlan
                  ? coursePlan.type === 'FREE'
                    ? 'бесплатный'
                    : formatPrice(coursePlan.price)
                  : ''
              }
            />
          </Datagrid>
        </ArrayField>
      </SimpleShowLayout>
    </Show>
  ) : null;
};

export default WithdrawalFundsRequestShow;
