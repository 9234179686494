import React from 'react';
import {
  List,
  Filter,
  Datagrid,
  TextField,
  TextInput,
  FunctionField,
  SelectInput,
} from 'react-admin';
import ListActions from './actions/ListActions';
import DateFieldForTimestamp from '../fields/dateFieldForTimestamp';

const filterChoices = [
  { id: 'actual', name: 'Только активные промокоды' },
  { id: 'archived', name: 'Только архивные промокоды' },
  { id: 'all', name: 'Все промокоды' },
];

const PromocodeFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label="Поиск" source="name" alwaysOn />
    <SelectInput
      alwaysOn
      allowEmpty={false}
      label="Отображать"
      source="filterType"
      choices={filterChoices}
      initialValue={'actual'}
    />
  </Filter>
);

const PromocodeList: React.FC<any> = (props) => {
  return (
    <List
      {...props}
      perPage={25}
      filters={<PromocodeFilter />}
      actions={<ListActions />}
      bulkActionButtons={false}
      filterDefaultValues={{
        filterType: 'actual',
      }}
    >
      <Datagrid rowClick="edit">
        <TextField source="name" label="Промокод" />
        <TextField source="plans" label="Тарифы" />
        <TextField source="amount" label="Скидка" />
        <TextField source="amountType" label="Тип скидки" />
        <DateFieldForTimestamp source="expiredAt" label="Дата окончания" />
        <TextField source="type" label="Тип" />
        <DateFieldForTimestamp
          source="nextPaymentAt"
          label="Дата следующего платежа"
        />
        <FunctionField
          label="Срок действия"
          render={(record: any): string | undefined =>
            record.nextPaymentCalculated &&
            `${record.nextPaymentCalculated.months} месяцев`
          }
        />
      </Datagrid>
    </List>
  );
};

export default React.memo(PromocodeList);
