import React, { Fragment } from 'react';
import Check from '@material-ui/icons/Check';
import Clear from '@material-ui/icons/Clear';
import {
  Button,
  useNotify,
  useRedirect,
  useUpdateMany,
  useUnselectAll,
  CRUD_UPDATE_MANY,
} from 'react-admin';
import { BulkActionProps } from '../types';
import { ACCEPTED, DECLINED } from './constants';

const BulkAcceptButton: React.FC<BulkActionProps> = ({
  selectedIds,
  isChecked,
}) => {
  const notify = useNotify();
  const redirectTo = useRedirect();
  const unselectAll = useUnselectAll('withdrawal_fund');

  const [approve, { loading: approveLoading }] = useUpdateMany(
    'withdrawal_fund/approve',
    selectedIds,
    {},
    {
      action: CRUD_UPDATE_MANY,
      undoable: true,
      onSuccess: () => {
        notify('Вывод средств выполнен', 'info', {}, true);
        redirectTo('/withdrawal_fund');
        unselectAll();
      },
      onFailure: () =>
        notify('Не удалось вывести средства', 'warning', {}, true),
    },
  );

  const [changeStatus, { loading: statusLoading }] = useUpdateMany();

  const handleChangeStatus = (status: string) => {
    changeStatus(
      'withdrawal_fund',
      selectedIds,
      { status },
      {
        action: CRUD_UPDATE_MANY,
        undoable: true,
        onSuccess: () => {
          notify(
            status === DECLINED ? 'Заявка отклонена' : 'Заявка подтверждена',
            'info',
            {},
            true,
          );

          status === DECLINED ? redirectTo('/withdrawal_fund') : approve();
        },
        onFailure: () =>
          notify('Ошибка при обработке заявки', 'warning', {}, true),
      },
    );
  };

  return !isChecked ? (
    <Fragment>
      <Button
        label={'Одобрить заявку'}
        onClick={() => handleChangeStatus(ACCEPTED)}
        disabled={approveLoading || statusLoading}
      >
        <Check />
      </Button>
      <Button
        label={'Отклонить заявку'}
        onClick={() => handleChangeStatus(DECLINED)}
        disabled={approveLoading || statusLoading}
      >
        <Clear />
      </Button>
    </Fragment>
  ) : null;
};

export default React.memo(BulkAcceptButton);
