import React from 'react';
import { Admin, Resource } from 'react-admin';

import {
  AccountBalance,
  CardGiftcard,
  LibraryAdd,
  MonetizationOn,
  People,
  Payment,
  VpnKey,
  Receipt,
} from '@material-ui/icons';

import { UserList } from './user/list';
import UserShow from './user/show';

import PromocodeList from './promocode/list';
import PromocodeEdit from './promocode/edit';
import PromocodeCreate from './promocode/create';

import PaymentsRequestsEdit from './paymentRequest/edit';
import PaymentsRequestsList from './paymentRequest/list';
import PaymentsRequestsShow from './paymentRequest/show';

import WithdrawalFundsRequestsEdit from './withdrawalFund/edit';
import WithdrawalFundsRequestsList from './withdrawalFund/list';
import WithdrawalFundsRequestsShow from './withdrawalFund/show';

import SubscriptionsList from './subscription/list';
import SubscriptionCreate from './subscription/create';
import SubscriptionEdit from './subscription/edit';
import SubscriptionShow from './subscription/show';

import { dataProvider } from './core/data.provider';
import authProvider from './core/auth.provider';
import { ResourceWithPermission } from './access-control';
import { FundList } from './fund/list';
import FundShow from './fund/show';
import { TokenList } from './token/list';
import { TokenCreate } from './token/create';
import { AddonPurchaseList } from './addonsPurchases/list';
import { AddonList } from './addon/list';
import AddonCreate from './addon/create';
import AddonEdit from './addon/edit';
import AddonShow from './addon/show';
import UserEdit from './user/edit';
import { InvoiceList } from './invoice/list';
import InvoiceShow from './invoice/show';

const App = () => {
  return (
    <Admin dataProvider={dataProvider} authProvider={authProvider}>
      <ResourceWithPermission
        name="user"
        list={UserList}
        icon={People}
        show={UserShow}
        edit={UserEdit}
      />

      <ResourceWithPermission
        name="payment_request"
        icon={Payment}
        options={{ label: 'Payments requests' }}
        list={PaymentsRequestsList}
        show={PaymentsRequestsShow}
        edit={PaymentsRequestsEdit}
      />
      <ResourceWithPermission
        name="withdrawal_fund"
        icon={MonetizationOn}
        options={{ label: 'Withdrawal funds' }}
        list={WithdrawalFundsRequestsList}
        show={WithdrawalFundsRequestsShow}
        edit={WithdrawalFundsRequestsEdit}
      />
      <ResourceWithPermission
        name="promo_code"
        icon={CardGiftcard}
        options={{ label: 'Promocodes' }}
        list={PromocodeList}
        edit={PromocodeEdit}
        create={PromocodeCreate}
      />
      <ResourceWithPermission
        name="subscription"
        options={{ label: 'Subscriptions' }}
        list={SubscriptionsList}
        edit={SubscriptionEdit}
        create={SubscriptionCreate}
        show={SubscriptionShow}
      />
      <Resource name="payment-order" />
      <ResourceWithPermission
        name="fund"
        icon={AccountBalance}
        options={{ label: 'Funds' }}
        list={FundList}
        show={FundShow}
      />
      <ResourceWithPermission
        name="token"
        icon={VpnKey}
        options={{ label: 'Access tokens' }}
        list={TokenList}
        create={TokenCreate}
      />
      <ResourceWithPermission
        name="addon-purchase"
        icon={LibraryAdd}
        options={{ label: 'Addons purchases' }}
        list={AddonPurchaseList}
      />
      <ResourceWithPermission
        name="addon"
        icon={LibraryAdd}
        list={AddonList}
        create={AddonCreate}
        edit={AddonEdit}
        show={AddonShow}
      />
      <ResourceWithPermission
        name="invoice"
        icon={Receipt}
        list={InvoiceList}
        show={InvoiceShow}
      />
    </Admin>
  );
};

export default App;
