import React from 'react';
import {
  ArrayField,
  Datagrid,
  DateField,
  FunctionField,
  Show,
  SimpleShowLayout,
  TextField,
  useShowController,
} from 'react-admin';
import { renderOrderStatus, renderOrderType } from './list';
import { formatPrice } from '../utils/format';
import PriceField from '../fields/PriceField';
import InvoiceActions from './actions';

export const renderTotalAmount = (record) =>
  formatPrice((record.amount + record.addonsAmount) / 100);

export const renderAmount = (record) => formatPrice(record.amount / 100);

export const renderAddonsAmount = (record) =>
  formatPrice(record.addonsAmount / 100);

const InvoiceShow = (props) => {
  const { record } = useShowController(props);

  if (!record) return null;

  return (
    <Show
      actions={
        <InvoiceActions
          data={record}
          basePath={props.basePath}
          resource={props.resource}
        />
      }
      {...props}
    >
      <SimpleShowLayout>
        <TextField source="_id" label="Order ID" />
        <TextField source="user" label="User ID" />
        <DateField source="createdAt" label="Дата покупки" showTime />
        <FunctionField label="Способ приобретения" render={renderOrderType} />
        <FunctionField label="Статус" render={renderOrderStatus} />
        <FunctionField label="Итоговая сумма" render={renderTotalAmount} />
        <TextField source="planName" label="Тариф" />
        <FunctionField label="Стоимость тарифа" render={renderAmount} />
        <ArrayField source="addons" fieldKey="_id">
          <Datagrid>
            <TextField source="title" />
            <PriceField source="actualAmount" />
            <TextField source="paymentPeriod" />
            <DateField source="nextPaymentAt" showTime />
          </Datagrid>
        </ArrayField>
        <FunctionField label="Стоимость аддонов" render={renderAddonsAmount} />
        <TextField
          source="paymentProfile.name"
          label="Наименование организации"
        />
        <TextField source="paymentProfile.inn" label="ИНН организации" />
        <TextField source="paymentProfile.kpp" label="КПП организации" />
      </SimpleShowLayout>
    </Show>
  );
};

export default InvoiceShow;
