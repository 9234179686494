import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { API_HOST } from '../config';
import { getToken } from './auth.store';

const baseURL = API_HOST;

const tokenInterceptor = (config: AxiosRequestConfig) => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
};

const api: AxiosInstance = axios.create({ baseURL });
api.interceptors.request.use(tokenInterceptor);

export { api };
