import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from '@material-ui/core';
import { useMutation, CRUD_UPDATE } from 'react-admin';

export const MutableCheckBoxField = ({
  source = '',
  record = {} as any,
  resource,
}) => {
  const value = record[source] ?? false;

  const [update, { loading }] = useMutation(
    {
      type: 'update',
      resource: resource,
      payload: { id: record.id, data: { admin: !value } },
    },
    {
      action: CRUD_UPDATE,
    },
  );

  const onChange = async () => {
    await update();
  };

  return (
    <Checkbox
      checked={value}
      onClick={(e) => e.stopPropagation()}
      onChange={onChange}
      disabled={loading}
    />
  );
};

MutableCheckBoxField.propTypes = {
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
  resource: PropTypes.string,
  basePath: PropTypes.string,
};
