import React from 'react';
import { useLocation } from 'react-router-dom';
import {
  Datagrid,
  TopToolbar,
  List,
  TextField,
  FilterForm,
  FilterButton,
  DateInput,
  ExportButton,
  Button,
  TextInput,
} from 'react-admin';
import PriceField from '../fields/PriceField';
import Box from '@material-ui/core/Box';
import IconToc from '@material-ui/icons/Toc';
import toPairs from 'lodash/fp/toPairs';
import { format } from 'date-fns';
const formatDate = (date) => {
  if (!date || date.toString().match(/-/)) return date;

  return format(new Date(date).getTime() * 1000, 'yyyy-MM-dd');
};
const parseDate = (date) => Math.round(new Date(date).getTime() / 1000);
const filters = [
  <TextInput key="search" source="search" label="Поиск" />,
  <DateInput
    label="Дата создания позже"
    source="created_gte"
    key="createdFrom"
    parse={parseDate}
    format={formatDate}
    defaultValue={Date.now() / 1000}
  />,
  <DateInput
    label="Дата создания раньше"
    source="created_lte"
    key="createdTo"
    format={formatDate}
    parse={parseDate}
    defaultValue={Date.now() / 1000}
  />,
];

const ListActions: React.FC<any> = () => {
  const location = useLocation();

  const handleExport = () => {
    const { search } = location;

    const filterData = JSON.parse(
      decodeURIComponent(search.match(/filter=(.*?)[&$]/)?.[1] ?? '{}'),
    );

    const searchString = toPairs(filterData)
      .map(
        (pair, index) =>
          `filter[${index}]=${encodeURIComponent(
            pair[0] + '||cont||' + pair[1],
          )}`,
      )
      .join('&');

    window.open(
      `${process.env.REACT_APP_API_HOST}/fund/export?${searchString}`,
    );
  };

  return (
    <TopToolbar className="filtered-header">
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        width="100%"
      >
        <FilterForm filters={filters} />
        <div>
          <FilterButton filters={filters} />
          <ExportButton />
          <Button
            label="Export to XLSX"
            startIcon={<IconToc />}
            onClick={handleExport}
          />
        </div>
      </Box>
    </TopToolbar>
  );
};

export const FundList = (props) => (
  <List {...props} bulkActionButtons={false} actions={<ListActions />}>
    <Datagrid rowClick="show">
      <TextField source="mail" />
      <TextField source="givenName" />
      <PriceField source="currentBalance" />
      <TextField source="id" />
    </Datagrid>
  </List>
);
