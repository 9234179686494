import { TextField } from 'react-admin';
import React from 'react';
import { formatPrice } from '../utils/format';

const PriceField = (props) => {
  const { source, record } = props;
  const price = record?.[source];

  return (
    <TextField
      {...props}
      record={{
        ...record,
        [source]: formatPrice(price ? price / 100 : 0),
      }}
    />
  );
};

export default PriceField;
