import { format, fromUnixTime } from 'date-fns';

export const formatPrice = (number) =>
  new Intl.NumberFormat('Ru-ru', {
    style: 'currency',
    currency: 'RUB',
  }).format(number);

export const formatUnix = (number?: number) =>
  number ? format(fromUnixTime(number), 'dd.MM.yyyy') : '';
