import { fromUnixTime, format } from 'date-fns';

export const dateFormatter = (value: number) => {
  if (!value || typeof value !== 'number') {
    return value;
  }

  return format(fromUnixTime(value), 'yyyy-MM-dd');
};

export const dateParser = (value: string) =>
  Math.floor(Date.parse(value) / 1000);
