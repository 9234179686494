/* eslint-disable @typescript-eslint/camelcase */
import { SelectOption } from '../types';

export const PAYMENT_REQUEST_NOT_CONNECTED_STATUS = 'not_connected';
export const PAYMENT_REQUEST_IN_PROCESS_STATUS = 'in_process';
export const PAYMENT_REQUEST_CONNECTED_STATUS = 'connected';
export const PAYMENT_REQUEST_ARCHIVED_STATUS = 'archived';

export const TypesLabels = {
  legalEntity: 'Юридическое лицо',
  individualEntrepreneur: 'Индивидуальный предприниматель',
  selfEmployed: 'Самозанятый',
};

export const StatusLabels = {
  in_process: 'Заявка на рассмотрении',
  not_connected: 'Заявка не подтверждена',
  connected: 'Заявка подтверждена',
};

export const ActsOnBasesOfLabels = {
  power_of_attorney: 'Доверенность',
  rule: 'Устав',
};

export const STATUS_OPTIONS: SelectOption[] = [
  { id: PAYMENT_REQUEST_IN_PROCESS_STATUS, name: 'Заявка на рассмотрени' },
  { id: PAYMENT_REQUEST_NOT_CONNECTED_STATUS, name: 'Заявка не подтверждена' },
  { id: PAYMENT_REQUEST_CONNECTED_STATUS, name: 'Заявка подтверждена' },
];
