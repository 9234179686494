import React from 'react';
import {
  Edit,
  PasswordInput,
  SaveButton,
  SimpleForm,
  TextField,
  TextInput,
  Toolbar,
} from 'react-admin';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  item: {
    width: '400px',
  },
});

const UserEditToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton disabled={props.pristine} />
  </Toolbar>
);

const UserEdit = (props) => {
  const styles = useStyles();

  return (
    <Edit {...props}>
      <SimpleForm toolbar={<UserEditToolbar />}>
        <TextField source="givenName" label="Имя" />
        <TextField source="mail" label="Email" />
        <TextInput
          source="liveDigital.externalId"
          label="ID пользователя в LiveDigital"
          className={styles.item}
        />
        <TextInput
          source="liveDigital.email"
          label="Email пользователя в LiveDigital"
          type="email"
          className={styles.item}
        />
        <PasswordInput
          source="liveDigital.password"
          label="Пароль пользователя в LiveDigital"
          className={styles.item}
        />
        <TextInput
          source="liveDigital.spaceId"
          label="ID пространства в LiveDigital"
          className={styles.item}
        />
      </SimpleForm>
    </Edit>
  );
};

export default UserEdit;
