import React from 'react';
import {
  List,
  Filter,
  Datagrid,
  SelectInput,
  FunctionField,
} from 'react-admin';

import { WithdrawalFundsRequest } from './types';
import { StatusLabels, STATUS_OPTIONS, NEW } from './constants';
import { formatPrice, formatUnix } from '../utils/format';

const WithdrawalFundsRequestsFilter = (props) => (
  <Filter {...props}>
    <SelectInput
      alwaysOn
      source="status"
      label="Статус заявки"
      choices={STATUS_OPTIONS}
    />
  </Filter>
);

const WithdrawalFundsRequestsList = (props) => {
  return (
    <List
      {...props}
      perPage={25}
      filters={<WithdrawalFundsRequestsFilter />}
      sort={{ field: 'created', order: 'DESC' }}
      bulkActionButtons={false}
      filterDefaultValues={{ status: NEW }}
    >
      <Datagrid rowClick="show">
        <FunctionField
          label="Имя"
          // @ts-expect-error
          render={({ givenName }: WithdrawalFundsRequest) => givenName}
        />
        <FunctionField
          label="email"
          // @ts-expect-error
          render={({ mail }: WithdrawalFundsRequest) => mail}
        />
        <FunctionField
          label="Сумма"
          // @ts-expect-error
          render={({ amount }: WithdrawalFundsRequest) =>
            formatPrice(amount / 100)
          }
        />
        <FunctionField
          label="Дата создания заявки"
          source="created"
          // @ts-expect-error
          render={({ created }: WithdrawalFundsRequest) => formatUnix(created)}
        />
        <FunctionField
          label="Статус"
          // @ts-expect-error
          render={({ status }: WithdrawalFundsRequest) =>
            `${StatusLabels[status]}`
          }
        />
      </Datagrid>
    </List>
  );
};

export default React.memo(WithdrawalFundsRequestsList);
