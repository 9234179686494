import React from 'react';
import { ShowButton, TopToolbar, ListButton } from 'react-admin';
import BulkAcceptButton from '../BulkAcceptButton';
import { PaymentsRequestsActionsProps } from './types';
import { PAYMENT_REQUEST_CONNECTED_STATUS } from '../constants';

const PaymentsRequestsEditActions: React.FC<PaymentsRequestsActionsProps> = (
  props,
) => (
  <TopToolbar>
    <BulkAcceptButton
      basePath={props.basePath}
      resource={props.resource}
      selectedIds={[props.data.id]}
      isRejecting={props.data.status === PAYMENT_REQUEST_CONNECTED_STATUS}
    />
    <ListButton label="Список" basePath={props.basePath} />
    <ShowButton
      label="Просмотр"
      record={props.data}
      basePath={props.basePath}
      resource={props.resource}
    />
  </TopToolbar>
);

export default React.memo(PaymentsRequestsEditActions);
