import React, { useState } from 'react';
import {
  required,
  Create,
  DateInput,
  TextInput,
  SimpleForm,
  NumberInput,
  SelectInput,
  BooleanInput,
  CheckboxGroupInput,
} from 'react-admin';
import { Box, Typography } from '@material-ui/core';
import { plans, planTypes, amountTypes } from './plans';
import { dateFormatter, dateParser } from '../helpers/timestampDateInput';
import ClearFieldsButton from './clearFieldsButton';

const PromocodeCreate: React.FC<any> = (props) => {
  const [calculateType, setCalculateType] = useState('');
  const [isNumOfUsesAllowedShow, setIsNumOfUsesAllowedShow] = useState(false);

  return (
    <Create {...props}>
      <SimpleForm redirect="list">
        <TextInput
          source="name"
          validate={required()}
          label="Название промокода"
          format={(value: any) => (value || '').toUpperCase()}
        />

        <CheckboxGroupInput source="plans" label="Тарифы" choices={plans} />

        <NumberInput
          min={100}
          source="amount"
          label="Скидка"
          validate={required()}
        />

        <SelectInput
          label="Тип скидки"
          source="amountType"
          choices={amountTypes}
          initialValue={amountTypes[0].id}
          validate={required()}
        />

        <DateInput
          source="expiredAt"
          label="Действителен до"
          validate={required()}
          format={dateFormatter}
          parse={dateParser}
        />

        <SelectInput
          label="Тип"
          source="type"
          choices={planTypes}
          validate={required()}
          initialValue={planTypes[0].id}
          onChange={(e) =>
            e.target.value === 'LIMITED'
              ? setIsNumOfUsesAllowedShow(true)
              : setIsNumOfUsesAllowedShow(false)
          }
        />

        {isNumOfUsesAllowedShow && (
          <NumberInput
            source="numOfUsesAllowed"
            label="Количество использований"
          />
        )}

        <Box mt="1em" />

        <BooleanInput source="isArchived" label="Архивный" />

        <Box mt="1em" />

        {!calculateType ? (
          <>
            <ClearFieldsButton
              text="Установить дату следующего платежа"
              type="nextPaymentAt"
              setCalculateType={setCalculateType}
            />
            <ClearFieldsButton
              text="Рассчитать дату следуюшего платежа"
              type="nextPaymentCalculated"
              setCalculateType={setCalculateType}
            />
          </>
        ) : calculateType === 'nextPaymentAt' ? (
          <ClearFieldsButton
            text="Рассчитать дату следуюшего платежа"
            type="nextPaymentCalculated"
            setCalculateType={setCalculateType}
          />
        ) : calculateType === 'nextPaymentCalculated' ? (
          <ClearFieldsButton
            text="Установить дату следующего платежа"
            type="nextPaymentAt"
            setCalculateType={setCalculateType}
          />
        ) : null}

        {calculateType === 'nextPaymentAt' && (
          <>
            <Typography gutterBottom>Дата следующего платежа</Typography>
            <DateInput
              source="nextPaymentAt"
              label="Дата следующего платежа"
              format={dateFormatter}
              parse={dateParser}
            />
          </>
        )}

        {calculateType === 'nextPaymentCalculated' && (
          <>
            <Typography gutterBottom>Дата следующего платежа через</Typography>
            <NumberInput
              source="nextPaymentCalculated.months"
              label="Количество месяцев"
            />
          </>
        )}
      </SimpleForm>
    </Create>
  );
};

export default PromocodeCreate;
