import React, { Fragment } from 'react';
import { useEditController } from 'react-admin';
import LegalEntityEdit from './edits/legalEntityEdit';
import SelfEmployedEdit from './edits/selfEmployedEdit';
import IndividualEntrepreneurEdit from './edits/individualEntrepreneurEdit';

const PaymentsRequestsEdit: React.FC<any> = (props) => {
  const { record } = useEditController(props);

  return (
    <Fragment>
      {record && record.profileType === 'individualEntrepreneur' && (
        <IndividualEntrepreneurEdit data={record} {...props} />
      )}
      {record && record.profileType === 'selfEmployed' && (
        <SelfEmployedEdit data={record} {...props} />
      )}
      {record && record.profileType === 'legalEntity' && (
        <LegalEntityEdit data={record} {...props} />
      )}
    </Fragment>
  );
};

export default React.memo(PaymentsRequestsEdit);
