import { fetchUtils } from 'react-admin';
import crudProvider from '@fusionworks/ra-data-nest-crud';

import { API_HOST } from '../config';
import { getToken } from './auth.store';

interface Options {
  headers?: Headers;
}

const httpClient = async (url: string, options: Options = {}) => {
  const token = getToken();
  if (token) {
    options.headers =
      options.headers ?? new Headers({ Accept: 'application/json' });
    options.headers.set('Authorization', `Bearer ${token}`);
  }
  return fetchUtils.fetchJson(url, options);
};

export const dataProvider = crudProvider(API_HOST, httpClient);
