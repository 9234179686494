import React from 'react';
import { useUpdate, Button } from 'react-admin';
import { PAYMENT_ORDER_CONFIRMED_STATUS } from './constants';

export const ApproveButton = (props) => {
  const { record } = props;

  const [confirm, { loading }] = useUpdate(
    'invoice',
    record?.id,
    { status: PAYMENT_ORDER_CONFIRMED_STATUS },
    record,
  );
  return (
    <Button
      label='Перевести в статус "Оплачен"'
      onClick={confirm}
      disabled={loading}
    />
  );
};
