import React, { Fragment } from 'react';
import { useShowController } from 'react-admin';
import IndividualEntrepreneurShow from './shows/individualEntrepreneurShow';
import SelfEmployedShow from './shows/selfEmployedShow';
import LegalEntityShow from './shows/legalEntityShow';

const PaymentsRequestsShow = (props) => {
  const { record } = useShowController(props);

  return (
    <Fragment>
      {record && record.profileType === 'individualEntrepreneur' && (
        <IndividualEntrepreneurShow data={record} {...props} />
      )}
      {record && record.profileType === 'selfEmployed' && (
        <SelfEmployedShow data={record} {...props} />
      )}
      {record && record.profileType === 'legalEntity' && (
        <LegalEntityShow data={record} {...props} />
      )}
    </Fragment>
  );
};

export default PaymentsRequestsShow;
