import { format, fromUnixTime } from 'date-fns';
import React from 'react';
import {
  Show,
  SimpleShowLayout,
  TextField,
  FunctionField,
  BooleanField,
} from 'react-admin';
import { dateParser } from 'src/helpers/timestampDateInput';

const formatDate = (date) => format(fromUnixTime(date), 'dd.MM.yyyy');

const SubscriptionShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="email" label="Email пользователя" />
      <TextField source="planName" label="Тариф" />
      <FunctionField
        label="Дата следующего платежа"
        // @ts-expect-error
        render={({ nextPaymentAt }) =>
          nextPaymentAt && formatDate(nextPaymentAt)
        }
      />
      <TextField source="createdBy" label="Назначил пользователь" />
      <TextField source="comment" label="Комментарий" />
      <TextField source="reason" label="Причина" />
      <FunctionField
        label="Договора"
        // @ts-expect-error
        render={({ licenseAgreement }) =>
          licenseAgreement
            ? `№${licenseAgreement.id} от ${formatDate(
                dateParser(licenseAgreement.date),
              )}`
            : 'Нет'
        }
      />

      <BooleanField
        source="deactivateCourses"
        label="Деактивировать курсы при удалении подписки из UMS"
      />
    </SimpleShowLayout>
  </Show>
);

export default SubscriptionShow;
