/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

import {
  List as MList,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
} from '@material-ui/core';
import {
  List,
  Datagrid,
  TextField,
  Filter,
  TextInput,
  useRecordContext,
  useMutation,
  CRUD_UPDATE,
} from 'react-admin';
import { MutableCheckBoxField } from 'src/fields/checkboxField';
import { useACL } from '../access-control';
import WidgetsIcon from '@material-ui/icons/Widgets';
import CheckIcon from '@material-ui/icons/Check';

const textInputStyle = {
  width: '300px',
};

const UserFilter = (props) => (
  <Filter {...props} fullWidth>
    <TextInput label="Search" source="search" alwaysOn style={textInputStyle} />
    <TextInput
      label="Search by userId"
      source="userId"
      alwaysOn
      style={textInputStyle}
    />
    <TextInput
      label="Search by referrerId"
      source="referrerId"
      alwaysOn
      style={textInputStyle}
    />
  </Filter>
);

const FEATURE_FLAGS = [
  {
    id: 'isWebinarAddonAllowed',
    label: 'Аддон "Вебинары"',
  },
  {
    id: 'isChatGPTAllowed',
    label: 'ChatGPT',
  },
  {
    id: 'isSamlSsoAllowed',
    label: 'SAML SSO',
  },
];

const BLOCKS = [
  {
    id: 'FinamAnswer',
    label: 'Финам открытый вопрос',
  },
  {
    id: 'FinamQuiz',
    label: 'Финам тест',
  },
];

export const BlocksField = ({
  label,
  source,
}: {
  label: string;
  source: string;
}) => {
  const record = useRecordContext();
  const [isOpened, setIsOpened] = useState(false);
  const [customBlocksItems, setCustomBlocksItems] = useState<string[]>([]);
  const [featureFlagsItems, setFeatureFlagsItems] = useState<string[]>([]);

  useEffect(() => {
    record.customBlocks && setCustomBlocksItems(record.customBlocks);
  }, [record.id, source]);

  useEffect(() => {
    record.featureFlags && setFeatureFlagsItems(record.featureFlags);
  }, [record.id, source]);

  const [update] = useMutation(
    {
      type: 'update',
      resource: 'user',
      payload: { id: record.id },
    },
    {
      action: CRUD_UPDATE,
    },
  );

  const clickCustomBlockHandle = (id) => {
    const customBlocks = customBlocksItems.includes(id)
      ? customBlocksItems.filter((item) => item !== id)
      : [...customBlocksItems, id];
    setCustomBlocksItems(customBlocks);
    update({ payload: { data: { customBlocks } } });
  };

  const clickFeatureFlagsHandle = (id) => {
    const featureFlags = featureFlagsItems.includes(id)
      ? featureFlagsItems.filter((item) => item !== id)
      : [...featureFlagsItems, id];
    setFeatureFlagsItems(featureFlags);
    update({ payload: { data: { featureFlags } } });
  };

  return (
    <div
      className="blocks-container"
      onClick={(e) => {
        setIsOpened(!isOpened);
        e.stopPropagation();
      }}
    >
      <WidgetsIcon />
      {isOpened && (
        <div className="blocks-list" onClick={(e) => e.stopPropagation()}>
          <MList subheader={<ListSubheader>Features</ListSubheader>}>
            {FEATURE_FLAGS.map(({ id, label }, index) => (
              <ListItem
                key={`block-${index}`}
                onClick={() => clickFeatureFlagsHandle(id)}
              >
                <ListItemIcon>
                  {featureFlagsItems.includes(id) && <CheckIcon />}
                </ListItemIcon>
                <ListItemText primary={label} />
              </ListItem>
            ))}
          </MList>
          <MList
            subheader={<ListSubheader>Кастом блоки пользователя</ListSubheader>}
          >
            {BLOCKS.map(({ id, label }, index) => (
              <ListItem
                key={`block-${index}`}
                onClick={() => clickCustomBlockHandle(id)}
              >
                <ListItemIcon>
                  {customBlocksItems.includes(id) && <CheckIcon />}
                </ListItemIcon>
                <ListItemText primary={label} />
              </ListItem>
            ))}
          </MList>
        </div>
      )}
    </div>
  );
};

export const UserList = (props) => {
  const { edit } = useACL('user');

  return (
    <List {...props} bulkActionButtons={false} filters={<UserFilter />}>
      <Datagrid rowClick="show">
        <TextField source="mail" />
        {edit && <MutableCheckBoxField source="admin" />}
        <TextField source="givenName" />
        <TextField source="id" />
        {edit && <BlocksField label="" source="customBlocks" />}
      </Datagrid>
    </List>
  );
};
