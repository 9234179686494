import React from 'react';
import { DateField } from 'react-admin';
import { fromUnixTime } from 'date-fns';

const DateFieldForTimestamp = (props) => {
  const { source, record } = props;

  const recordWithTimestampAsDate = {
    ...record,
    [source]: record?.[source] ? fromUnixTime(record[source]) : null,
  };

  return <DateField {...props} record={recordWithTimestampAsDate} />;
};

export default DateFieldForTimestamp;
