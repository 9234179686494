import React, { useState } from 'react';
import {
  Edit,
  required,
  TextField,
  DateInput,
  SimpleForm,
  NumberInput,
  SelectInput,
  BooleanInput,
  CheckboxGroupInput,
  useEditController,
} from 'react-admin';
import { Box, Typography } from '@material-ui/core';
import { plans, planTypes, amountTypes } from './plans';
import EditToolbar from './actions/EditToolbar';
import { dateFormatter, dateParser } from '../helpers/timestampDateInput';
import ClearFieldsButton from './clearFieldsButton';

const PromocodeEdit: React.FC<any> = (props) => {
  const { record } = useEditController(props);
  const isNextPaymentAt = record ? record.nextPaymentAt : null;
  const isNextPaymentCalculated = record ? record.nextPaymentCalculated : null;
  const isNumOfUsesAllowed = record ? record.type === 'LIMITED' : false;
  const [isNumOfUsesAllowedShow, setIsNumOfUsesAllowedShow] = useState(
    isNumOfUsesAllowed,
  );
  const [calculateType, setCalculateType] = useState(
    isNextPaymentAt
      ? 'nextPaymentAt'
      : isNextPaymentCalculated
      ? 'nextPaymentCalculated'
      : '',
  );

  return (
    <Edit {...props}>
      <SimpleForm redirect="list" toolbar={<EditToolbar />}>
        <TextField source="name" label="Название промокода" />

        <CheckboxGroupInput source="plans" choices={plans} label="Тарифы" />

        <NumberInput
          min={100}
          source="amount"
          validate={required()}
          label="Скидка"
        />

        <SelectInput
          label="Тип скидки"
          source="amountType"
          choices={amountTypes}
          initialValue={amountTypes[0].id}
          validate={required()}
        />

        <DateInput
          source="expiredAt"
          label="Действителен до"
          validate={required()}
          format={dateFormatter}
          parse={dateParser}
        />

        <SelectInput
          label="Тип"
          source="type"
          choices={planTypes}
          validate={required()}
          onChange={(e) =>
            e.target.value === 'LIMITED'
              ? setIsNumOfUsesAllowedShow(true)
              : setIsNumOfUsesAllowedShow(false)
          }
        />

        {isNumOfUsesAllowedShow && (
          <NumberInput
            source="numOfUsesAllowed"
            label="Количество использований"
          />
        )}

        <Box mt="1em" />

        <BooleanInput source="isArchived" label="Архивный" />

        <Box mt="1em" />

        {!calculateType ? (
          <>
            <ClearFieldsButton
              text="Установить дату следующего платежа"
              type="nextPaymentAt"
              setCalculateType={setCalculateType}
            />
            <ClearFieldsButton
              text="Рассчитать дату следуюшего платежа"
              type="nextPaymentCalculated"
              setCalculateType={setCalculateType}
            />
          </>
        ) : calculateType === 'nextPaymentAt' ? (
          <ClearFieldsButton
            text="Рассчитать дату следуюшего платежа"
            type="nextPaymentCalculated"
            setCalculateType={setCalculateType}
          />
        ) : calculateType === 'nextPaymentCalculated' ? (
          <ClearFieldsButton
            text="Установить дату следующего платежа"
            type="nextPaymentAt"
            setCalculateType={setCalculateType}
          />
        ) : null}

        {calculateType === 'nextPaymentAt' && (
          <>
            <Typography gutterBottom>Дата следующего платежа</Typography>
            <DateInput
              source="nextPaymentAt"
              label="Дата следующего платежа"
              format={dateFormatter}
              parse={dateParser}
            />
          </>
        )}

        {calculateType === 'nextPaymentCalculated' && (
          <>
            <Typography gutterBottom>Дата следующего платежа через</Typography>
            <NumberInput
              source="nextPaymentCalculated.months"
              label="Количество месяцев"
            />
          </>
        )}
      </SimpleForm>
    </Edit>
  );
};

export default React.memo(PromocodeEdit);
