import React from 'react';
import {
  Edit,
  required,
  TextField,
  SimpleForm,
  SelectInput,
} from 'react-admin';
import PaymentsRequestsEditActions from '../actions/paymentsRequestsEditActions';
import { STATUS_OPTIONS } from '../constants';

const SelfEmployedEdit: React.FC<any> = (props) => {
  return (
    <Edit
      actions={
        <PaymentsRequestsEditActions
          data={props.data}
          basePath={props.basePath}
          resource={props.resource}
        />
      }
      {...props}
    >
      <SimpleForm redirect="list">
        <TextField source="id" />
        <SelectInput
          source="status"
          validate={[required()]}
          choices={STATUS_OPTIONS}
        />
      </SimpleForm>
    </Edit>
  );
};

export default React.memo(SelfEmployedEdit);
