import React from 'react';
import Check from '@material-ui/icons/Check';
import {
  Button,
  useNotify,
  useRedirect,
  useUpdateMany,
  useUnselectAll,
  CRUD_UPDATE_MANY,
} from 'react-admin';
import { BulkActionProps } from '../types';
import {
  PAYMENT_REQUEST_CONNECTED_STATUS,
  PAYMENT_REQUEST_NOT_CONNECTED_STATUS,
} from './constants';
import { useACL } from '../access-control';

const BulkAcceptButton: React.FC<BulkActionProps> = ({
  selectedIds,
  isRejecting,
}) => {
  const notify = useNotify();
  const redirectTo = useRedirect();
  const unselectAll = useUnselectAll('payment_request');
  const { edit } = useACL('payment_request');

  const [approve, { loading: approveLoading }] = useUpdateMany(
    'payment_request/approve',
    selectedIds,
    {},
    {
      action: CRUD_UPDATE_MANY,
      undoable: true,
      onSuccess: () => {
        notify('Платежи подключены', 'info', {}, true);
        redirectTo('/payment_request');
        unselectAll();
      },
      onFailure: () => notify('Платежи не подключены', 'warning', {}, true),
    },
  );

  const [changeStatus, { loading: statusLoading }] = useUpdateMany(
    'payment_request',
    selectedIds,
    {
      status: isRejecting
        ? PAYMENT_REQUEST_NOT_CONNECTED_STATUS
        : PAYMENT_REQUEST_CONNECTED_STATUS,
    },
    {
      action: CRUD_UPDATE_MANY,
      undoable: true,
      onSuccess: () => {
        notify(
          isRejecting ? 'Заявка отклонена' : 'Заявка подтверждена',
          'info',
          {},
          true,
        );

        isRejecting ? redirectTo('/payment_request') : approve();
      },
      onFailure: () =>
        notify('Ошибка при обработке заявки', 'warning', {}, true),
    },
  );

  const button = (
    <Button
      label={isRejecting ? 'Отклонить заявку' : 'Подключить платежи'}
      onClick={changeStatus}
      disabled={approveLoading || statusLoading}
    >
      <Check />
    </Button>
  );

  return edit ? button : null;
};

export default React.memo(BulkAcceptButton);
