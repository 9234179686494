import React from 'react';
import {
  Show,
  TextField,
  EmailField,
  BooleanField,
  FunctionField,
  SimpleShowLayout,
} from 'react-admin';
import PaymentsRequestsActions from '../actions/paymentsRequestsActions';
import { Box, Typography } from '@material-ui/core';
import { TypesLabels, StatusLabels } from '../constants';
import { PaymentsRequest } from '../types';

const SelfEmployedShow = (props) => (
  <Show
    actions={
      <PaymentsRequestsActions
        data={props.data}
        basePath={props.basePath}
        resource={props.resource}
      />
    }
    {...props}
  >
    <SimpleShowLayout>
      <TextField label="Пользователь" source="user" />
      <FunctionField
        label="Статус"
        // @ts-expect-error
        render={(record: PaymentsRequest) => `${StatusLabels[record.status]}`}
      />
      <FunctionField
        label="Вид дица"
        // @ts-expect-error
        render={(record: PaymentsRequest) =>
          `${TypesLabels[record.profileType]}`
        }
      />
      <TextField source="id" />
      <TextField source="inn" label="ИНН" />
      <TextField source="fullName" label="ФИО" />
      <TextField source="address" label="Адрес регистрации" />
      <TextField source="phone" label="Телефон" />
      <EmailField source="email" />

      <Box mt="1em" />

      <Typography variant="h5" gutterBottom>
        Банковские данные
      </Typography>
      <TextField source="paymentAccount" label="Расчётный счёт" />
      <TextField source="bank" label="Банк" />
      <TextField source="bik" label="БИК" />
      <TextField source="corrAccount" label="Корреспондентский счёт" />
      <BooleanField source="termsAgree" label="Согласен с офертой" />
    </SimpleShowLayout>
  </Show>
);

export default SelfEmployedShow;
