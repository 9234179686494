import {
  Datagrid,
  DateField,
  Filter,
  FunctionField,
  List,
  TextField,
  TextInput,
  DateInput,
  SelectInput,
} from 'react-admin';
import React from 'react';
import PriceField from '../fields/PriceField';
import { endOfMonth, format, startOfMonth } from 'date-fns';
import { OrderStatuses, STATUS_OPTIONS } from './constants';

const renderOrderType = (record) => {
  if (record.type === 'INITIAL') {
    return 'Покупка в составе подписки';
  } else if (record.type === 'INITIAL_ADDON') {
    return 'Отдельная покупка';
  } else if (record.type === 'AUTO_PAYMENT') {
    return 'Автопродление в составе подписки';
  } else if (record.type === 'AUTO_PAYMENT_ADDON') {
    return 'Автопродление аддона';
  } else {
    return record.type;
  }
};

const AddonPurchaseFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search (user id/email)" source="search" alwaysOn />
    <TextInput label="Название аддона" source="addonTitle" alwaysOn />
    <SelectInput
      alwaysOn
      source="status"
      label="Статус"
      choices={STATUS_OPTIONS}
    />
    <DateInput
      label="Дата оплаты от"
      source="from"
      defaultValue={format(startOfMonth(new Date()), 'yyyy-MM-dd')}
    />
    <DateInput
      label="Дата оплаты по"
      source="to"
      defaultValue={format(endOfMonth(new Date()), 'yyyy-MM-dd')}
    />
  </Filter>
);

export const AddonPurchaseList = (props) => {
  return (
    <List
      {...props}
      bulkActionButtons={false}
      filters={<AddonPurchaseFilter />}
      filterDefaultValues={{ status: OrderStatuses.CONFIRMED }}
    >
      <Datagrid>
        <DateField source="createdAt" label="Дата покупки" />
        <FunctionField
          source="type"
          label="Способ приобретения"
          render={renderOrderType}
        />
        <TextField source="title" label="Название аддона" />
        <PriceField source="actualAmount" />
        <TextField source="mail" label="User email" />
        <TextField source="givenName" label="User name" />
        <TextField source="userId" label="User Id" />
      </Datagrid>
    </List>
  );
};
