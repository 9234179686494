import React from 'react';
import { DateField, Show, SimpleShowLayout, TextField } from 'react-admin';

const AddonShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="email" label="Email пользователя" />
      <TextField source="title" label="Название" />
      <DateField source="nextPaymentAt" label="nextPaymentAt" />
      <TextField source="createdBy" label="Назначил пользователь" />
      <TextField source="comment" label="Комментарий" />
      <TextField source="reason" label="Причина" />
    </SimpleShowLayout>
  </Show>
);

export default AddonShow;
