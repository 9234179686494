import React from 'react';
import {
  BulkDeleteButton,
  Datagrid,
  FunctionField,
  List,
  TextField,
  DateInput,
  TopToolbar,
  CreateButton,
  FilterForm,
  FilterButton,
  useListContext,
  SelectInput,
  BooleanInput,
} from 'react-admin';
import { format, fromUnixTime } from 'date-fns';
import Box from '@material-ui/core/Box';
import { SubscriptionsRequest } from './types';
import { useACL } from '../access-control';

// const SubscriptionsFilter = (props: any) => (
//   <Filter {...props}>
//     <TextInput label="Поиск" source="email" alwaysOn />
//   </Filter>
// );
const parseDate = (date) => Math.round(new Date(date).getTime() / 1000);
const formatDate = (date) => {
  if (!date || date.toString().match(/-/)) return date;

  return format(new Date(date).getTime() * 1000, 'yyyy-MM-dd');
};
const filters = [
  <SelectInput
    label="Автопродление"
    source="status"
    allowEmpty={false}
    key="status"
    choices={[
      { id: 'ACTIVE', name: 'Включено' },
      { id: 'INACTIVE', name: 'Выключено' },
    ]}
  />,
  <BooleanInput key="isFromUms" label="UMS" source="isFromUms" />,
  <DateInput
    label="Дата создания позже"
    source="created_gte"
    key="createdFrom"
    parse={parseDate}
    format={formatDate}
    defaultValue={Date.now() / 1000}
  />,
  <DateInput
    label="Дата создания раньше"
    source="created_lte"
    key="createdTo"
    format={formatDate}
    parse={parseDate}
    defaultValue={Date.now() / 1000}
  />,
];
const ListActions: React.FC<any> = () => {
  const { create } = useACL('promo_code');

  const { basePath } = useListContext();
  return (
    <TopToolbar className="filtered-header">
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        width="100%"
      >
        <FilterForm filters={filters} />
        <div>
          <FilterButton filters={filters} />
          {create && <CreateButton basePath={basePath} />}
        </div>
      </Box>
    </TopToolbar>
  );
};

const SubscriptionsBulkActionButtons = (props) => {
  const { edit } = useACL('subscription');

  return <>{edit && <BulkDeleteButton {...props} />}</>;
};

const SubscriptionsList = (props) => {
  return (
    <List
      {...props}
      perPage={25}
      actions={<ListActions />}
      bulkActionButtons={<SubscriptionsBulkActionButtons {...props} />}
    >
      <Datagrid rowClick="show">
        <TextField source="user" label="Пользователь" />
        <TextField source="email" label="Email пользователя" />
        <TextField source="status" label="Статус" />
        <TextField source="nextPaymentAmount" label="Сумма след. списания" />
        <TextField source="planName" label="Тариф" />
        <TextField source="reason" label="Причина" />
        <FunctionField
          label="Следующее списание"
          source="nextPaymentAt"
          // @ts-expect-error
          render={(record: SubscriptionsRequest) =>
            record.nextPaymentAt &&
            format(fromUnixTime(record.nextPaymentAt), 'dd.MM.yyyy')
          }
        />
        <FunctionField
          label="Дата создания"
          source="created"
          // @ts-expect-error
          render={(record: SubscriptionsRequest) =>
            record.created && format(fromUnixTime(record.created), 'dd.MM.yyyy')
          }
        />
      </Datagrid>
    </List>
  );
};

export default SubscriptionsList;
