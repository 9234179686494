import React, { useState } from 'react';
import {
  Datagrid,
  Filter,
  List,
  TextField,
  TextInput,
  FunctionField,
  SimpleShowLayout,
  RichTextField,
} from 'react-admin';
import DateFieldForTimestamp from '../fields/dateFieldForTimestamp';
import {
  Button,
  ClickAwayListener,
  makeStyles,
  Tooltip,
} from '@material-ui/core';

const useStyles = makeStyles({
  token: {
    wordBreak: 'break-all',
  },
  copyButton: {
    marginTop: 10,
  },
});

const TokenFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="search" alwaysOn />
  </Filter>
);

const TokenView = ({ record }) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const handleCopyButtonClick = async () => {
    await navigator.clipboard.writeText(record.token);

    handleTooltipOpen();

    setTimeout(handleTooltipClose, 1000);
  };

  return (
    <SimpleShowLayout record={record} className={classes.token}>
      <RichTextField source="token" />
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <div>
          <Tooltip
            PopperProps={{
              disablePortal: true,
            }}
            onClose={handleTooltipClose}
            open={open}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title="Copied"
          >
            <Button
              variant="outlined"
              onClick={handleCopyButtonClick}
              color="primary"
              className={classes.copyButton}
            >
              Copy to clipboard
            </Button>
          </Tooltip>
        </div>
      </ClickAwayListener>
    </SimpleShowLayout>
  );
};

export const TokenList = (props) => {
  return (
    <List {...props} bulkActionButtons={false} filters={<TokenFilter />}>
      <Datagrid expand={TokenView}>
        <DateFieldForTimestamp source="created" label="Создан" />
        <TextField source="user" label="CoreApp User" />
        <TextField source="author" label="UMS Author" />
        <FunctionField
          label="Token"
          render={(record) => `...${(record?.token || '').slice(-20)}`}
        />
      </Datagrid>
    </List>
  );
};
