import React from 'react';
import {
  Edit,
  required,
  FileField,
  TextField,
  FileInput,
  SimpleForm,
  SelectInput,
  useEditController,
  Toolbar,
  SaveButton,
} from 'react-admin';
import WithdrawalRequestsEditActions from './actions/withdrawalRequestsEditActions';
import { NEW, STATUS_OPTIONS } from './constants';

const WithdrawalEditToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton disabled={props.pristine} />
  </Toolbar>
);

const WithdrawalFundsRequestsEdit = (props) => {
  const { record } = useEditController(props);

  return (
    <Edit
      actions={
        <WithdrawalRequestsEditActions
          // @ts-expect-error
          data={record}
          basePath={props.basePath}
          resource={props.resource}
        />
      }
      {...props}
    >
      <SimpleForm redirect="list" toolbar={<WithdrawalEditToolbar />}>
        <TextField source="id" />
        <SelectInput
          source="status"
          validate={[required()]}
          choices={STATUS_OPTIONS}
          disabled={record?.status !== NEW}
        />
        <FileInput source="files" label="Платежное поручение">
          <FileField source="src" title="title" />
        </FileInput>
      </SimpleForm>
    </Edit>
  );
};

export default WithdrawalFundsRequestsEdit;
