import React from 'react';
import {
  Create,
  DateInput,
  email,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
} from 'react-admin';
import { dateFormatter, dateParser } from '../helpers/timestampDateInput';
import { addons } from './addons';
import { addonReasons } from './addon-reasons';

const AddonCreate: React.FC<any> = (props) => {
  return (
    <Create {...props}>
      <SimpleForm redirect="list">
        <TextInput
          source="email"
          type="email"
          label="Email пользователя"
          validate={[email(), required()]}
        />

        <SelectInput source="addonName" label="Аддон" choices={addons} />

        <DateInput
          source="nextPaymentAt"
          label="Дата следующего платежа"
          validate={required()}
          format={dateFormatter}
          parse={dateParser}
        />

        <TextInput
          multiline
          source="comment"
          type="comment"
          label="Комментарий"
        />

        <SelectInput
          source="reason"
          label="Причина"
          choices={addonReasons}
          validate={required()}
        />
      </SimpleForm>
    </Create>
  );
};

export default AddonCreate;
