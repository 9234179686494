// TODO: fix TS errors
import React from 'react';
import {
  List,
  Datagrid,
  FunctionField,
  Filter,
  SelectInput,
} from 'react-admin';
import { format } from 'date-fns';
import { PaymentsRequest } from './types';
import BulkAcceptButtonList from './BulkAcceptButton';
import { TypesLabels, StatusLabels, STATUS_OPTIONS } from './constants';

const PaymentsRequestsFilter = (props) => (
  <Filter {...props}>
    <SelectInput
      alwaysOn
      source="status"
      label="Статус заявки"
      choices={STATUS_OPTIONS}
    />
  </Filter>
);

const PaymentsRequestsList = (props) => {
  const getNameField = (record: PaymentsRequest): string | undefined =>
    record.profileType === 'legalEntity'
      ? record.fullOrganisationName
      : record.fullName;

  return (
    <List
      {...props}
      perPage={25}
      filters={<PaymentsRequestsFilter />}
      sort={{ field: 'modified', order: 'DESC' }}
      bulkActionButtons={
        <BulkAcceptButtonList
          basePath={props.basePath}
          resource={props.resource}
          filterValues={props.filterValues}
          selectedIds={props.selectedIds}
        />
      }
    >
      <Datagrid rowClick="show">
        <FunctionField
          label="Вид лица"
          source="profileType"
          // @ts-expect-error
          render={(record: PaymentsRequest) =>
            `${TypesLabels[record.profileType]}`
          }
        />

        <FunctionField
          label="Имя"
          // @ts-expect-error
          render={getNameField}
        />
        <FunctionField
          label="Статус"
          // @ts-expect-error
          render={(record: PaymentsRequest) => `${StatusLabels[record.status]}`}
        />
        <FunctionField
          label="Дата создания заявки"
          source="modified"
          // @ts-expect-error
          render={
            (record: PaymentsRequest) =>
              record.modified &&
              format(new Date(record.modified * 1000), 'dd.MM.yyyy')
            // format(new Date(parseInt(record.created, 10) * 1000),'dd.MM.yyyy')
          }
        />
      </Datagrid>
    </List>
  );
};

export default PaymentsRequestsList;
