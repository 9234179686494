import { API_HOST } from '../../config';
import { getToken } from '../../core/auth.store';
import { WithdrawalFundsRequest } from '../types';

export const download = async (
  record: WithdrawalFundsRequest,
  path: string,
) => {
  const response = await fetch(
    `${API_HOST}/withdrawal_fund/${path}/${record.id}`,
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    },
  );

  const header = response.headers.get('Content-Disposition');
  if (header) {
    const parts = header.split(';');
    const filename = parts[1].split('=')[1].replaceAll('"', '');

    const blob = await response.blob();

    const url = window.URL.createObjectURL(blob);
    const hyperlink = document.createElement('a');
    hyperlink.href = url;
    hyperlink.download = filename;
    document.body.appendChild(hyperlink);
    hyperlink.click();
    hyperlink.remove();
  } else {
    const blob = await response.blob();

    const url = window.URL.createObjectURL(blob);
    window.location.assign(url);
  }
};
