import { api } from './api';
import { clearAuth, getGrants, getToken, storeAuth } from './auth.store';

const ERROR = new Error();

const login = async ({
  username,
  password,
}: {
  username: string;
  password: string;
}) => {
  const { data, status, statusText } = await api.post('auth/login', {
    username,
    password,
  });
  if (status < 200 || status >= 300) {
    throw new Error(statusText);
  }
  const { token, grants } = data;

  storeAuth(token, JSON.stringify(grants));
};

const logout = async () => {
  clearAuth();
};

const checkAuth = async () => {
  // TODO: implement refresh tokens
  const token = getToken();
  if (!token) {
    return Promise.reject({ message: false });
  }
};

const checkError = async ({ status }: { status: number }) => {
  if (status === 401 || status === 403) {
    clearAuth();
    throw ERROR;
  }
};

const getPermissions = async () => {
  const grants = getGrants();

  return Promise.resolve(grants);
};

export default {
  login,
  logout,
  checkAuth,
  checkError,
  getPermissions,
};
