import React from 'react';
import { TopToolbar, CreateButton, useListContext } from 'react-admin';
import { useACL } from '../../access-control';

const ListActions: React.FC<any> = (props) => {
  const { create } = useACL('promo_code');

  const { className } = props;
  const { basePath } = useListContext();
  return (
    <TopToolbar className={className}>
      {create && <CreateButton basePath={basePath} />}
    </TopToolbar>
  );
};

export default ListActions;
