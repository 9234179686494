import React from 'react';
import { TopToolbar } from 'react-admin';
import { useACL } from '../access-control';
import { ApproveButton } from './ApproveButton';
import { PAYMENT_ORDER_CONFIRMED_STATUS } from './constants';

const InvoiceActions = (props) => {
  const { edit } = useACL('invoice');

  return edit && props.data.status !== PAYMENT_ORDER_CONFIRMED_STATUS ? (
    <TopToolbar>
      <ApproveButton record={props.data} />
    </TopToolbar>
  ) : (
    <></>
  );
};

export default React.memo(InvoiceActions);
