import React from 'react';
import {
  BooleanInput,
  DateInput,
  Edit,
  required,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  useEditController,
} from 'react-admin';
import { dateFormatter, dateParser } from '../helpers/timestampDateInput';
import { plans } from '../promocode/plans';
import { reasons } from './reasons';

const SubscriptionEdit = (props) => {
  const { record } = useEditController(props);
  const isFromUms = record?.isFromUms;

  return (
    <Edit {...props}>
      <SimpleForm redirect="list">
        <TextField source="email" label="Email пользователя" />

        <SelectInput
          source="planName"
          label="Тариф"
          choices={plans}
          disabled={!isFromUms}
        />

        <DateInput
          source="nextPaymentAt"
          label="Дата следующего платежа"
          validate={required()}
          format={dateFormatter}
          parse={dateParser}
          disabled={!isFromUms}
        />

        <TextInput
          multiline
          source="comment"
          type="comment"
          label="Комментарий"
          disabled={!isFromUms}
        />

        <SelectInput
          source="reason"
          label="Причина"
          choices={reasons}
          validate={isFromUms && required()}
          disabled={!isFromUms}
        />

        <BooleanInput
          source="deactivateCourses"
          label="Деактивировать курсы при удалении подписки из UMS"
        />
      </SimpleForm>
    </Edit>
  );
};

export default React.memo(SubscriptionEdit);
