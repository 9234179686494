import React from 'react';
import {
  DateInput,
  DeleteButton,
  Edit,
  required,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  Toolbar,
  useRecordContext,
} from 'react-admin';
import { addons } from './addons';
import { addonReasons } from './addon-reasons';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

const AddonToolbar = (props) => {
  const { createdBy } = useRecordContext();
  const classes = useStyles();

  return !createdBy ? (
    <Toolbar>
      <div>Аддоны, созданные пользователем, доступны только для просмотра</div>
    </Toolbar>
  ) : (
    <Toolbar {...props} classes={classes}>
      <SaveButton disabled={props.pristine} />
      <DeleteButton />
    </Toolbar>
  );
};

const AddonEdit: React.FC<any> = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm redirect="list" toolbar={<AddonToolbar />}>
        <TextField source="email" label="Email пользователя" />

        <SelectInput source="name" label="Аддон" choices={addons} />

        <DateInput source="nextPaymentAt" label="Дата следующего платежа" />

        <TextInput
          multiline
          source="comment"
          type="comment"
          label="Комментарий"
        />

        <SelectInput
          source="reason"
          label="Причина"
          choices={addonReasons}
          validate={required()}
        />
      </SimpleForm>
    </Edit>
  );
};

export default AddonEdit;
