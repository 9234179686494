import React from 'react';
import {
  Show,
  TextField,
  EmailField,
  ImageField,
  BooleanField,
  FunctionField,
  SimpleShowLayout,
} from 'react-admin';
import { PaymentsRequest } from '../types';
import PaymentsRequestsActions from '../actions/paymentsRequestsActions';
import { Box, Typography } from '@material-ui/core';
import { TypesLabels, StatusLabels, ActsOnBasesOfLabels } from '../constants';

const LegalEntityShow = (props) => (
  <Show
    actions={
      <PaymentsRequestsActions
        data={props.data}
        basePath={props.basePath}
        resource={props.resource}
      />
    }
    {...props}
  >
    <SimpleShowLayout>
      <TextField label="Пользователь" source="user" />

      <FunctionField
        label="Статус"
        // @ts-expect-error
        render={(record: PaymentsRequest) => `${StatusLabels[record.status]}`}
      />
      <FunctionField
        label="Вид дица"
        // @ts-expect-error
        render={(record: PaymentsRequest) =>
          `${TypesLabels[record.profileType]}`
        }
      />
      <TextField source="coursesTheme" label="Тематика курсов" />
      <TextField source="id" />
      <TextField source="inn" label="ИНН" />
      <TextField
        source="fullOrganisationName"
        label="Полное юридическое наименование"
      />
      <TextField source="kpp" label="КПП" />
      <TextField source="ogrn" label="ОГРН" />

      <Box mt="1em" />

      <Typography variant="h5" gutterBottom>
        Адрес
      </Typography>
      <TextField source="legalAddress" label="Юридический адрес" />
      <TextField source="address" label="Почтовый адрес" />

      <Box mt="1em" />

      <Typography variant="h5" gutterBottom>
        Лицо для договоров
      </Typography>
      <TextField source="contractsPersonName" label="ФИО" />
      <TextField source="contractsPersonPosition" label="Должность" />

      <FunctionField
        label="Действует на основании"
        // @ts-expect-error
        render={(record: PaymentsRequest) =>
          `${ActsOnBasesOfLabels[record.actsOnBasesOf]}`
        }
      />

      {props.data.actsOnBasesOf === 'power_of_attorney' && (
        <TextField source="powerOfAttorneyNumber" label="Номер доверенности" />
      )}

      {props.data.actsOnBasesOf === 'power_of_attorney' && (
        <FunctionField
          label="Дата выдачи доверенности"
          // @ts-expect-error
          render={(record: PaymentsRequest) =>
            new Date(record.powerOfAttorneyIssueDate * 1000).toLocaleString()
          }
        />
      )}

      {props.data.actsOnBasesOf === 'power_of_attorney' && (
        <ImageField
          source="powerOfAttorney"
          label="Доверенность"
          title="Доверенность"
        />
      )}

      <Box mt="1em" />

      <Typography variant="h5" gutterBottom>
        Контактное лицо
      </Typography>
      <TextField source="contactPersonFullName" label="ФИО" />
      <TextField source="contactPersonPhone" label="Телефон" />
      <EmailField source="contactPersonEmail" label="Email" />

      <Box mt="1em" />

      <Typography variant="h5" gutterBottom>
        Банковские данные
      </Typography>
      <TextField source="paymentAccount" label="Расчётный счёт" />
      <TextField source="bank" label="Банк" />
      <TextField source="bik" label="БИК" />
      <TextField source="corrAccount" label="Корреспондентский счёт" />
      <BooleanField source="termsAgree" label="Согласен с офертой" />
    </SimpleShowLayout>
  </Show>
);

export default LegalEntityShow;
