import React from 'react';
import { DateInput, NumberInput, required, TextInput } from 'react-admin';
import { useFormState } from 'react-final-form';

import { dateFormatter, dateParser } from '../helpers/timestampDateInput';

export const ExtraFields: React.FC<any> = () => {
  const {
    values: { reason },
  } = useFormState();

  const licenceValidation =
    (field) =>
    (value, { licenseAgreement = { id: null, date: null } }) => {
      if (licenseAgreement[field]) return undefined;

      const { id, date } = licenseAgreement;
      return !!id !== !!date ? 'Required' : undefined;
    };

  const style = { width: '256px' };
  return reason === 'alternative_payment' ? (
    <>
      <div className="ra-input">
        <TextInput
          source="licenseAgreement.id"
          label="Номер лицензионного договора"
          style={style}
          validate={licenceValidation('id')}
        />
      </div>
      <div className="ra-input">
        <DateInput
          source="licenseAgreement.date"
          label="Дата лицензионного договора"
          format={(value) =>
            typeof value === 'number'
              ? dateFormatter(value)
              : dateFormatter(dateParser(value))
          }
          parse={dateParser}
          style={style}
          validate={licenceValidation('date')}
        />
      </div>
      <div className="ra-input">
        <NumberInput
          source="amount"
          label="Сумма"
          validate={[required()]}
          style={style}
        />
      </div>
    </>
  ) : null;
};
