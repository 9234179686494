import React from 'react';
import { Toolbar, SaveButton } from 'react-admin';

const EditToolbar: React.FC<any> = (props) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

export default EditToolbar;
