import { Datagrid, Filter, List, TextField, TextInput } from 'react-admin';
import React from 'react';
import PriceField from '../fields/PriceField';

const AddonFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search (user id/email)" source="search" alwaysOn />
    <TextInput label="Название аддона" source="addonTitle" alwaysOn />
  </Filter>
);

export const AddonList = (props) => {
  return (
    <List {...props} bulkActionButtons={false} filters={<AddonFilter />}>
      <Datagrid rowClick="show">
        <TextField source="title" label="Название аддона" />
        <PriceField source="actualAmount" />
        <TextField source="mail" label="User email" />
        <TextField source="givenName" label="User name" />
        <TextField source="userId" label="User Id" />
      </Datagrid>
    </List>
  );
};
