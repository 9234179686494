import React, { useCallback } from 'react';
import { useForm } from 'react-final-form';
import { Typography } from '@material-ui/core';

const buttonStyle = {
  color: '#fff',
  background: '#20a05b',
  padding: '10px 16px',
  margin: '0 15px 0 0',
  cursor: 'pointer',
  borderRadius: '4px',
  'text-transform': 'none',
};

const ClearFieldsButton = ({ text, type, setCalculateType, ...props }) => {
  const form = useForm();

  const handleClick = useCallback(() => {
    form.change('nextPaymentAt', undefined);
    form.change('nextPaymentCalculated', undefined);
  }, [form]);

  return (
    <Typography
      gutterBottom
      variant="button"
      style={buttonStyle}
      onClick={() => {
        setCalculateType(type);
        handleClick();
      }}
      {...props}
    >
      {text}
    </Typography>
  );
};

export default ClearFieldsButton;
