import React from 'react';
import { EditButton, TopToolbar, ListButton } from 'react-admin';
import BulkAcceptButton from '../BulkAcceptButton';
import { PaymentsRequestsActionsProps } from './types';
import { PAYMENT_REQUEST_CONNECTED_STATUS } from '../constants';
import { useACL } from '../../access-control';

const PaymentsRequestsActions: React.FC<PaymentsRequestsActionsProps> = (
  props,
) => {
  const { edit } = useACL('payment_request');

  return (
    <TopToolbar>
      <BulkAcceptButton
        basePath={props.basePath}
        resource={props.resource}
        selectedIds={[props.data.id]}
        isRejecting={props.data.status === PAYMENT_REQUEST_CONNECTED_STATUS}
      />
      <ListButton label="Список" basePath={props.basePath} />
      {edit && (
        <EditButton
          label="Редактировать"
          record={props.data}
          basePath={props.basePath}
          resource={props.resource}
        />
      )}
    </TopToolbar>
  );
};

export default React.memo(PaymentsRequestsActions);
