export const plans = [
  { id: 'pro#1', name: 'pro#1' },
  { id: 'pro#5', name: 'pro#5' },
  { id: 'pro#10', name: 'pro#10' },
  { id: 'guru', name: 'guru' },
];

export const planTypes = [
  { id: 'REGULAR', name: 'Постоянный' },
  { id: 'LIMITED', name: 'Ограниченный' },
  { id: 'ONE_TIME', name: 'Одноразовый' },
];

export const amountTypes = [
  { id: 'ABSOLUTE', name: 'Задать цену в копейках' },
  { id: 'RELATIVE', name: 'В процентах' },
];
