import React from 'react';
import { EditButton, TopToolbar, ListButton } from 'react-admin';
import BulkAcceptButton from '../BulkAcceptButton';
import { WithdrawalFundsRequestsActionsProps } from './types';
import { NEW } from '../constants';
import { useACL } from '../../access-control';
import XLSXReportButton from './XLSXReportButton';
import DOCXActButton from './DOCXActButton';

const WithdrawalFundsRequestsActions: React.FC<WithdrawalFundsRequestsActionsProps> = (
  props,
) => {
  const { edit } = useACL('withdrawal_fund');

  return (
    <TopToolbar>
      <BulkAcceptButton
        basePath={props.basePath}
        resource={props.resource}
        selectedIds={[props.data.id]}
        isChecked={props.data ? props.data.status !== NEW : false}
      />
      <ListButton label="Список" basePath={props.basePath} />
      {edit && (
        <EditButton
          label="Редактировать"
          record={props.data}
          basePath={props.basePath}
          resource={props.resource}
        />
      )}
      <XLSXReportButton record={props.data} />
      <DOCXActButton record={props.data} />
    </TopToolbar>
  );
};

export default React.memo(WithdrawalFundsRequestsActions);
