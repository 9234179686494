import { SelectOption } from '../types';

export const PAYMENT_ORDER_NEW_STATUS = 'NEW';
export const PAYMENT_ORDER_CONFIRMED_STATUS = 'CONFIRMED';
export const PAYMENT_ORDER_DRAFT_STATUS = 'DRAFT';
export const PAYMENT_ORDER_UNPAID_STATUS = 'UNPAID';

export const StatusLabels = {
  [PAYMENT_ORDER_NEW_STATUS]: 'Новый',
  [PAYMENT_ORDER_CONFIRMED_STATUS]: 'Оплачен',
  [PAYMENT_ORDER_DRAFT_STATUS]: 'Черновик',
  [PAYMENT_ORDER_UNPAID_STATUS]: 'Не оплачен',
};

export const STATUS_OPTIONS: SelectOption[] = Object.keys(
  StatusLabels,
).map((id) => ({ id, name: StatusLabels[id] }));
